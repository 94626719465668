import React, { useState } from 'react'
import { useEffect } from 'react'

import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import CategoryHeader from 'src/components/CategoryHeader'
import useDevice from 'src/hooks/useDevice'
import { Link } from 'gatsby'

import { GridContainer, GridCard } from './style'
import SpotlightNewsSmall from '../SpotlightNewsSmall'
import { CardProps } from '../SpotlightNewsSmall/types'

type DataGridType = {
  image: string
  slug: string
  title?: string
  gridArea: string
}
export type GridType = {
  showCategoryHeader?: boolean
  category: string
  categoryLabel: string
  slug: string
  template: string
  dados: DataGridType[] | CardProps[]
  removeGradient?: boolean
  isCategoryTemplate?: boolean
}

function getNumber(string: string): number {
  const number = string.replace(/\D/g, '')
  return Number(number)
}

const defaultStyles = {
  large: {
    fontSize: '32px',
    lineHeight: '40px',
  },
  medium: {
    fontSize: '32px',
    lineHeight: '40px',
  },
  small: {
    fontSize: '20px',
    lineHeight: '25px',
  },
}

function getStylesCard(gridArea: string) {
  const [, , row, column] = gridArea.split('/')
  if (getNumber(column) === 3) {
    return defaultStyles.large
  }
  if (getNumber(row) === 2 && getNumber(column) === 2) {
    return defaultStyles.medium
  }
  return defaultStyles.small
}

export const templates = {
  A: {
    rows: 2,
    mobile: {
      limit: 3,
      elements: [
        {
          gridArea: '1 / 1 / span 1 / span 4',
        },
        {
          gridArea: '2 / 1 / span 1 / span 4',
        },
        {
          gridArea: '3 / 1 / span 1 / span 4 ',
        },
      ],
    },
    tablet: {
      limit: 2,
      elements: [
        {
          gridArea: '1 / 1 / span 2 / span 2',
        },
        {
          gridArea: '1 / 3 / span 2 / span 2',
        },
      ],
    },
    desktopLG: {
      limit: 3,
      elements: [
        {
          gridArea: '1 / 1 / span 2 / span 2',
        },
        {
          gridArea: '1 / 3 / span 2 / span 1',
        },
        {
          gridArea: '1 / 4 / span 2 / span 1 ',
        },
      ],
    },
    desktopXL: {
      limit: 1,
      elements: [
        {
          gridArea: '1 / 1 / span 2 / span 4',
        },
      ],
    },
  },
  B: {
    rows: 2,
    mobile: {
      limit: 2,
      elements: [
        {
          gridArea: '1 / 1 / span 1 / span 4',
        },
        {
          gridArea: '2 / 1 / span 1 / span 4',
        },
      ],
    },
    tablet: {
      limit: 1,
      elements: [
        {
          gridArea: '1 / 1 / span 2 / span 4',
        },
      ],
    },
    desktopLG: {
      limit: 2,
      elements: [
        {
          gridArea: '1 / 1 / span 2 / span 2',
        },
        {
          gridArea: '1 / 3 / span 2 / span 2',
        },
      ],
    },
    desktopXL: {
      limit: 2,
      elements: [
        {
          gridArea: '1 / 1 / span 2 / span 2',
        },
        {
          gridArea: '1 / 3 / span 2 / span 2',
        },
      ],
    },
  },
  C: {
    rows: 2,
    mobile: {
      limit: 3,
      elements: [
        {
          gridArea: '1 / 1 / span 1 / span 4',
        },
        {
          gridArea: '2 / 1 / span 1 / span 4',
        },
        {
          gridArea: '3 / 1 / span 1 / span 4 ',
        },
      ],
    },
    tablet: {
      limit: 2,
      elements: [
        {
          gridArea: '1 / 1 / span 2 / span 2',
        },
        {
          gridArea: '1 / 3 / span 1 / span 2',
        },
        {
          gridArea: '2 / 3 / span 1 / span 2 ',
        },
      ],
    },
    desktopLG: {
      limit: 3,
      elements: [
        {
          gridArea: '1 / 1 / span 2 / span 2',
        },
        {
          gridArea: '1 / 3 / span 2 / span 1',
        },
        {
          gridArea: '1 / 4 / span 2 / span 1 ',
        },
      ],
    },
    desktopXL: {
      limit: 3,
      elements: [
        {
          gridArea: '1 / 1 / span 2 / span 2',
        },
        {
          gridArea: '1 / 3 / span 2 / span 1 ',
        },
        {
          gridArea: '1 / 4 / span 2 / span 1 ',
        },
      ],
    },
  },
  D: {
    rows: 2,
    mobile: {
      limit: 3,
      elements: [
        {
          gridArea: '1 / 1 / span 1 / span 4',
        },
        {
          gridArea: '2 / 1 / span 1 / span 4',
        },
        {
          gridArea: '3 / 1 / span 1 / span 4 ',
        },
      ],
    },
    tablet: {
      limit: 3,
      elements: [
        {
          gridArea: '1 / 1 / span 2 / span 2',
        },
        {
          gridArea: '1 / 3 / span 1 / span 2',
        },
        {
          gridArea: '2 / 3 / span 1 / span 2',
        },
      ],
    },
    desktopLG: {
      limit: 3,
      elements: [
        {
          gridArea: '1 / 1 / span 2 / span 2',
        },
        {
          gridArea: '1 / 3 / span 1 / span 2',
        },
        {
          gridArea: '2 / 3 / span 1 / span 2 ',
        },
      ],
    },
    desktopXL: {
      limit: 3,
      elements: [
        {
          gridArea: '1 / 1 / span 2 / span 2',
        },
        {
          gridArea: '1 / 3 / span 1 / span 2',
        },
        {
          gridArea: '2 / 3 / span 1 / span 2 ',
        },
      ],
    },
  },

  E: {
    rows: 1,
    mobile: {
      limit: 3,
      elements: [
        {
          gridArea: '1 / 1 / span 1 / span 4',
        },
        {
          gridArea: '2 / 1 / span 1 / span 4',
        },
        {
          gridArea: '3 / 1 / span 1 / span 4 ',
        },
      ],
    },
    tablet: {
      limit: 3,
      elements: [
        {
          gridArea: '1 / 1 / span 1 / span 1',
        },
        {
          gridArea: '1 / 2 / span 1 / span 1',
        },
        {
          gridArea: '1 / 3 / span 1 / span 1',
        },
      ],
    },
    desktopLG: {
      limit: 3,
      elements: [
        {
          gridArea: '1 / 1 / span 1 / span 1',
        },
        {
          gridArea: '1 / 2 / span 1 / span 1',
        },
        {
          gridArea: '1 / 3 / span 1 / span 1',
        },
      ],
    },
    desktopXL: {
      limit: 4,
      elements: [
        {
          gridArea: '1 / 1 / span 1 / span 1',
        },
        {
          gridArea: '1 / 2 / span 1 / span 1',
        },
        {
          gridArea: '1 / 3 / span 1 / span 1',
        },
        {
          gridArea: '1 / 4 / span 1 / span 1',
        },
      ],
    },
  },
  F: {
    rows: 2,
    mobile: {
      limit: 3,
      elements: [
        {
          gridArea: '1 / 1 / span 1 / span 4',
        },
        {
          gridArea: '2 / 1 / span 1 / span 4',
        },
        {
          gridArea: '3 / 1 / span 1 / span 4 ',
        },
      ],
    },
    tablet: {
      limit: 2,
      elements: [
        {
          gridArea: '1 / 1 / span 2 / span 2',
        },
        {
          gridArea: '1 / 3 / span 2 / span 2',
        },
      ],
    },
    desktopLG: {
      limit: 3,
      elements: [
        {
          gridArea: '1 / 1 / span 2 / span 2',
        },
        {
          gridArea: '1 / 3 / span 2 / span 1',
        },
        {
          gridArea: '1 / 4 / span 2 / span 1 ',
        },
      ],
    },
    desktopXL: {
      limit: 4,
      elements: [
        {
          gridArea: '1 / 1 / span 2 / span 2',
        },
        {
          gridArea: '2 / 3 / span 1 / span 1',
        },
        {
          gridArea: '1 / 3 / span 1 / span 1 ',
        },
        {
          gridArea: '1 / 4 / span 2 / span 1 ',
        },
      ],
    },
  },
}

const Grid = ({
  showCategoryHeader,
  category,
  categoryLabel,
  slug,
  template,
  dados,
  removeGradient,
  isCategoryTemplate,
}: GridType) => {
  const device = useDevice()
  const [sendDatalayerEvent] = useDataLayer()
  const [templateInformation, setTemplateInformation] = useState({})
  const templateGrid = getTemplate(template)
  function handleIndex(index: number) {
    return index
  }
  useEffect(() => {
    if (!device) {
      return
    }
    setTemplateInformation(getTemplateInformation(device))
  }, [device])
  function getTemplateInformation(deviceData: string) {
    let value
    if (deviceData) {
      value = templateGrid[deviceData]
    }
    return value
  }
  function getTemplate(data: string) {
    let value
    if (data) {
      value = templates[data]
    }
    return value
  }
  function getGridArea(position: number) {
    if (!Object.keys(templateInformation).length) {
      return false
    }
    if (!templateInformation.elements[position]) {
      return false
    }
    return templateInformation.elements[position]
  }
  function isOneRow() {
    if (!templateGrid) {
      return false
    }
    if (templateGrid.rows === 1) {
      return true
    }
    return false
  }
  if (!templateInformation) {
    return <div>Não foi possivel achar o template</div>
  }
  const filteredCards = () => {
    if (!Object.keys(templateInformation).length) {
      return false
    }
    return dados.reduce((all, item, index) => {
      if (templateInformation.limit < index + 1) {
        return [all]
      }
      const { gridArea } = getGridArea(index)
      const stylesCard = getStylesCard(gridArea)

      const link = () => {
        let linkNews = ''
        if (item.link) {
          linkNews = item.link
        }
        if (isCategoryTemplate && !item.isFromInset) {
          linkNews = `/${item.subcategorySlug}/${item.slug}`
        }
        if (!isCategoryTemplate && !item.isFromInset) {
          linkNews = `/${item.categorySlug}/${item.subcategorySlug}/${item.slug}`
        }
        if (item.isFromInset) {
          linkNews = `https://www.inset.com.br/${item.insetCategory}/${item.slug}`
        }
        return linkNews
      }

      return [
        ...all,
        <GridCard
          key={handleIndex(index)}
          gridArea={gridArea}
          backGroundImage={item.image}
          className={`${isOneRow() ? 'cardOneRow' : ''}'box'`}
          styles={stylesCard}
          removeGradient={removeGradient}
        >
          <Link
            to={link()}
            target={item.isFromInset ? '__blank' : '__self'}
            rel={item.isFromInset ? 'noreferrer' : ''}
            className="w-100 h-100 d-flex"
            onClick={() => {
              sendDatalayerEvent({
                section: 'Melhores conteúdos',
                element_action: 'click button',
                element_name: item.title,
                redirect_url: link(),
                section_name: 'Melhores conteúdos',
              })
            }}
          >
            <div className="image-area">
              <img loading="lazy" src={item.image} alt="" className="w-100 h-100" />
            </div>
            {item.subCategory && <span className="category-label">{item.subCategory}</span>}
            <div className="text-area">
              {item.title && (
                <div>
                  <h3
                    className={`fs-24 lh-30 ${
                      index === 0 ? 'fs-xl-40 lh-xl-50' : 'fs-xl-20 lh-xl-25 mb-0'
                    } d-block`}
                  >
                    {item.title}
                  </h3>
                  {index === 0 && (
                    <p className="fs-14 lh-17 fs-xl-16 lh-xl-19 mb-0">{item.title}</p>
                  )}
                </div>
              )}
            </div>
          </Link>
        </GridCard>,
      ]
    }, [])
  }
  return (
    <>
      {isOneRow() ? (
        <SpotlightNewsSmall
          data={{
            showCategoryHeader,
            category,
            categoryLabel,
            slug,
            template,
            dados,
            removeGradient,
          }}
        />
      ) : (
        <>
          {showCategoryHeader ? (
            <CategoryHeader
              category={categoryLabel}
              categoryLink={`/${category}`}
              className="mb-3"
            />
          ) : (
            ''
          )}

          <GridContainer gridRows={templateGrid.rows}>
            <div className="grid-column-style">{filteredCards({ templateInformation })}</div>
          </GridContainer>
        </>
      )}
    </>
  )
}

export default Grid
