import React from 'react'

import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import { Link } from 'gatsby'
// Themes
import useWidth from 'src/hooks/useWidth'

// Style
import { Section, Hero, Dots } from './style'
// Types
import { Custom } from '../types'

const responsive = {
  mobile: { breakpoint: { max: 464, min: 0 }, items: 1 },
}

type colorProps = {
  primary: string
  primary300: string
  primary200: string
  primary100: string
}

type categoryPros = {
  icon: string
  id: string
  name: string
  slug: string
  subTitle: string
  template: string
  colors: colorProps
}

type ItemsProps = {
  image: string
  slug: string
  title: string
  subtitle: string
  category: categoryPros
  subCategory?: string
  categorySlug: string
  subcategorySlug: string
  segmentSlug: string
  isFromInset?: boolean
  insetCategory?: string
}
type DadosProps = {
  dados: ItemsProps[]
}
type DataProps = {
  data: DadosProps
}

const CarouselTimerMobile = ({ data }: DataProps) => {
  const WIDTH_MD = 768
  const windowWidth = useWidth(200)
  const [sendDatalayerEvent] = useDataLayer()
  const CustomDot = ({ onClick, active }: Custom) => {
    function handleClick() {
      onClick()
    }
    return (
      <Dots
        className={active ? 'active' : 'inactive'}
        onClick={windowWidth < WIDTH_MD ? handleClick : onClick}
      />
    )
  }

  const filteredData = data.dados.filter((item: ItemsProps) => item.slug.includes('bom-dia-inter'))
  const reorderedData = filteredData.length > 0 ? [filteredData[0], ...data.dados.filter((item: ItemsProps) => !item.slug.includes('bom-dia-inter'))] : data.dados

  return (
    <Section>
      <Carousel
        responsive={responsive}
        showDots
        slidesToSlide={1}
        swipeable
        additionalTransfrom={0}
        containerClass="col-12 p-0"
        draggable
        focusOnSelect={false}
        keyBoardControl
        minimumTouchDrag={80}
        renderButtonGroupOutside={false}
        renderDotsOutside
        removeArrowOnDeviceType={['tablet', 'mobile', 'desktop']}
        infinite
        autoPlay
        autoPlaySpeed={4000}
        customDot={<CustomDot />}
      >
        {reorderedData.slice(0, 3).map((item: ItemsProps) => (
          <Link
            key={item.title}
            to={
              !item.isFromInset
                ? `/${item.categorySlug}/${item.subcategorySlug}/${item.slug}/`
                : `https://www.inset.com.br/${item.insetCategory}/${item.slug}/`
            }
            onClick={() => {
              sendDatalayerEvent({
                section: '',
                element_action: 'click button',
                element_name: item.category.name,
                section_name: item.title,
                redirect_url: `${process.env.BASE_URL}/${item.categorySlug}/${item.subcategorySlug}/${item.slug}/`,
              })
            }}
            target={item.isFromInset ? '__blank' : ''}
            rel={item.isFromInset ? 'noreferrer' : ''}
            title={item.title}
          >
            <Hero
              style={{
                backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.072) 19.27%, rgba(0, 0, 0, 0.72) 100%), 
                  url(${item.image})`,
              }}
              bg={item.category.colors.primary}
            >
              {item.subCategory && <span className="category-label">{item.subCategory}</span>}
              <h3
                className="f-sora fs-24 lh-30 fw-600 mb-4 text-white title"
                dangerouslySetInnerHTML={{ __html: item.title }}
              />
            </Hero>
          </Link>
        ))}
      </Carousel>
    </Section>
  )
}

export default CarouselTimerMobile
