import styled from 'styled-components'
import { breakpoints } from 'src/styles/breakpoints'

type LabelProps = {
  bg?: string
}

export const Row = styled.div`
  @media (min-width: ${breakpoints.md}) {
    flex-direction: row-reverse;
  }

  @media (min-width: ${breakpoints.lg}) {
    flex-direction: row;
  }
`

export const CardBigNews = styled.div`
  cursor: pointer;
  position: relative;
  border-radius: 16px;
  overflow: hidden;

  &:hover {
    img {
      transform: scale(1.1);
      transition: all 0.2s ease-in-out 0s;
    }
  }

  figure {
    width: 100%;
    height: 240px;

    figcaption {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      padding: 18px;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%), url(.jpg);
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    @media (min-width: ${breakpoints.md}) {
      width: 100%;
      height: 336px;
    }

    @media (min-width: ${breakpoints.lg}) {
      height: 352px;
    }

    @media (min-width: ${breakpoints.xl}) {
      height: 424px;
    }
  }
`

export const Card = styled.div`
  position: relative;
  cursor: pointer;
  border-radius: 16px;
  overflow: hidden;

  &:hover {
    img {
      transform: scale(1.1);
      transition: all 0.2s ease-in-out 0s;
    }
  }

  figure {
    width: 100%;
    height: 240px;

    figcaption {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      padding: 18px;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%), url(.jpg);
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    @media (min-width: ${breakpoints.md}) {
      width: 100%;
      height: 164px;
    }

    @media (min-width: ${breakpoints.lg}) {
      height: 352px;
    }

    @media (min-width: ${breakpoints.xl}) {
      height: 424px;
    }
  }
`

export const Label = styled.span`
  padding: 1px 4px;
  background: ${(props: LabelProps) => props.bg};
  color: #fff;
  font-size: 12px;
  line-height: 15px;
  font-family: Inter;
  font-weight: bold;
  position: absolute;
  top: 24px;
  left: 24px;
  border-radius: 4px;
`
