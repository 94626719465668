import styled from 'styled-components'

type HeroProps = {
  bg?: string
}

export const Section = styled.section`
  position: relative;

  .react-multi-carousel-dot-list {
    height: 4px;
    margin: 24px;
    justify-content: flex-start;
  }

  a {
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }
  }
`

export const Hero = styled.div`
  position: relative;
  width: 100%;
  height: 240px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 24px;
  border-radius: 12px;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: left;

  label {
    position: absolute;
    top: 24px;
    padding: 2px 4px;
    background-color: ${(props: HeroProps) => props.bg};
    border-radius: 4px;
  }
  .category-label {
    position: absolute;
    top: 24px;
    left: 24px;
    font-size: 12px;
    line-height: 15px;
    background-color: ${(props: HeroProps) => props.bg};
    padding: 2px 4px;
    border-radius: 4px;
    color: #fff;
    font-weight: bold;
  }
  .title {
    overflow: hidden;
    height: 90px;
  }
`

export const Dots = styled.div`
  position: relative;
  width: 48px;
  height: 4px;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    width: 100%;
    height: 4px;
    background-color: #fff;
    border-radius: 4px;
    opacity: 0.32;
    left: 0;
  }

  & + div {
    margin-left: 8px;
  }

  @keyframes progress {
    from {
      width: 0%;
    }
    to {
      width: 100%;
    }
  }

  &.active::after {
    content: '';
    position: absolute;
    width: 0%;
    top: 0;
    height: 4px;
    border-radius: 4px;
    background: #fff;
    animation: progress;
    animation-duration: 4.1s;
    opacity: 1;
    left: 0;
  }
`
