import React from 'react'

import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
// Data

// Components
import Grid from 'src/components/Grid'
import IconOrangeDs from 'src/components/Icon/OrangeDsIcon'

type dadosProps = {
  id: string
  slug: string
  title: string
  image: string
  publishedAt: string
  video: boolean
  isBestContent: boolean
  subCategory: string
  label: string
  category: string
  link: string
}

type bannerFillProps = {
  showCategoryHeader: boolean
  template: string
  dados: dadosProps[]
}

type BestContentsProps = {
  bannerFill: bannerFillProps
}

const BestContents = ({ bannerFill }: BestContentsProps) => (
  <>
    <div className="mb-5">
      <div className="d-flex align-items-center mb-3">
        <div className="mr-2">
          <IconOrangeDs size="MD" color="#ffA700" icon="receipt" />
        </div>
        <h2 className="fs-20 lh-25 fs-md-24 lh-md-30 fw-600 m-0 text-grayscale--500">
          Melhores conteúdos
        </h2>
      </div>
      <p className="fs-16 lh-19 fs-md-18 lh-md-22 fw-400 text-grascale--500">
        Para você que busca se informar ao máximo para tomar as melhores decisões em seus
        investimentos
      </p>
    </div>
    <Grid {...bannerFill} />
  </>
)

export default BestContents
