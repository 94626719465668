import styled from 'styled-components'
import { white } from 'src/styles/colors'
import { device } from 'src/styles/breakpoints'

type GridContainerType = {
  gridRows: number
}
type GridCardType = {
  gridArea: string
  backGroundImage: string
  styles: string
  removeGradient?: boolean
  primary: string
}

export const GridContainer = styled.div`
  display: flex;
  flex-direction: column;
  .grid-column-style {
    @media ${device.tablet} {
      display: grid;
      grid-template-columns: ${(props: GridContainerType) =>
        props.gridRows > 1 ? 'repeat(4, 1fr)' : 'auto'};
      grid-template-rows: ${(props) => `repeat(${props.gridRows},210px)`};
      grid-gap: 20px;
    }
  }
`

export const GridCard = styled.div`
  display: flex;
  min-height: 240px;
  height: 240px;
  padding: 20px;
  justify-content: end;
  align-items: flex-end;
  grid-area: ${(props: GridCardType) => props.gridArea};
  ${(props: GridCardType) => props.styles};
  border-radius: 12px;
  margin-top: 20px;
  overflow: hidden;
  position: relative;
  background: ${(props: GridCardType) =>
    props.removeGradient ? 'none' : 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%)'};
  .category-label {
    position: absolute;
    top: 24px;
    left: 24px;
    font-size: 12px;
    line-height: 15px;
    background-color: ${({ theme }) => theme.colors.primary};
    padding: 0 14px;
    border-radius: 4px;
  }
  .text-area {
    display: flex;
    align-items: flex-end;
  }
  .image-area {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  img {
    transition: all 0.2s ease-in-out 0s;

    position: absolute;
    object-fit: cover;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
  :hover {
    background: linear-gradient(
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.1) 19.27%,
      rgb(0 0 0 / 59%) 100%
    );

    img {
      transform: scale(1.1);
    }
  }
  a {
    font-size: 20px;
    color: ${white};
    text-decoration: none;
    font-weight: 600;
    :hover {
      color: ${white};
    }
  }
  @media ${device.tablet} {
    margin-top: 0;
    min-height: unset;
    height: unset;
  }

  @media ${device.desktopLG} {
    a {
      font-size: inherit;
    }
  }
`
